
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import ExcelBtn from '@/modules/common/components/excel-btn.vue';
import type ClusterMarketsService from '../../cluster-markets.service';
import ProviderFilter from '../_filters/providers-market.vue';

@Component({
    components: {
        ProviderFilter,
        ExcelBtn,
    },
})
export default class MarketsClusterActions extends Vue {
    @inject(KEY.ClusterMarketsService) clusterMarketsService!: ClusterMarketsService;

    downloadAction() {
        return this.clusterMarketsService.downloadExcel();
    }
}
